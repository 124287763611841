import { graphqlRequest } from '@api/client';
import { CollectionType, CollectionTypeType } from '@utils/types/collections';
import { stringifyArrayForGQL } from '@utils/helpers';

export const GET_ALL_COLLECTIONS = (types: CollectionTypeType[]) => `
  query getAllCollection{
    allCollection(
      where: { _: { is_draft: false }, type: { in: ${stringifyArrayForGQL(types)} } }
    ) {
      name
      title
      description
      order
      slug {
        current
      }
      type
      image {
        asset {
          url
        }
      }
      related_listings {
        guestyId
      }
      attractions {
        title
        description
        image  {
          asset {
            url
          }
        }
      }
    }
  }
`;

export async function getCollectionsByType(types: CollectionTypeType[]): Promise<CollectionType[]> {
  const cmsCollection = await graphqlRequest(
    'getAllCollection',
    GET_ALL_COLLECTIONS(types),
    {},
  );

  return Array.from(cmsCollection.data.data.allCollection);
}
