import { Button } from '@atoms/button';
import { Logo } from '@atoms/logo';
import { HeaderMenu } from '@molecules/headerMenu';
import { TabsNavigation } from '@molecules/tabsNavigation';
import { UserHeaderButton } from '@molecules/userHeaderButton';
import { ModalNames, useModals } from '@utils/context/modals';
import { useSession } from '@utils/context/session';
import { useIsMobile } from '@utils/hooks/useIsMobile';
import { BaseComponentProps } from '@utils/types/baseComponents';
import {
  SegmentEventNamesEnum,
  SegmentEventPagesEnum,
  useAnalytics,
} from '@utils/hooks/useAnalytics';
import { MarketType } from '@utils/types/city';
import { useGetMarkets } from '@utils/hooks/api/useGetMarkets';
import { BurgerMenu } from 'assets/icons';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import React, { useMemo, useState, useEffect, useCallback } from 'react';
import Link from 'next/link';
import styles from './styles.module.scss';

export type HeaderProps = {} & BaseComponentProps;

Header.defaultProps = {};

/** Header Component */
export function Header(props: HeaderProps) {
  const router = useRouter();
  const { containerStyle } = props;
  const isMobile = useIsMobile();
  const { openModal } = useModals();
  const { user } = useSession();
  const [isHostDropdownOpen, setIsHostDropdownOpen] = useState(false);
  const { trackEvent } = useAnalytics();
  const [markets, setMarkets] = useState<MarketType[]>([]);

  const { data } = useGetMarkets(!markets);

  useEffect(() => {
    if (data) setMarkets(data.map(({ name, slug }) => ({ name, slug: slug.current })));
  }, [data]);

  const onLoginClick = () => {
    trackEvent(SegmentEventNamesEnum.LOGIN_BUTTON_CLICK, {
      PageName: SegmentEventPagesEnum.HOME_PAGE,
    });
  };

  const onSignUpClick = () => {
    trackEvent(SegmentEventNamesEnum.SIGNUP_BUTTON_CLICK, {
      PageName: SegmentEventPagesEnum.HOME_PAGE,
    });
  };

  const openHeaderMenu = () => openModal(ModalNames.MOBILE_MENU);

  const onAlreadyOwnHomeClick = () => {};
  const onWantToBuyHomeClick = () => {};

  const renderHostDropdown = useCallback(() => (
    <>
      <a
        target="_blank"
        href="https://owners.rovetravel.com"
        rel="noopener noreferrer"
      >
        I already own a home
      </a>
      <a
        target="_blank"
        href="https://owners.rovetravel.com/buy-with-rove"
        rel="noopener noreferrer"
      >
        I want to buy a home
      </a>
    </>
  ), []);

  const renderBrowseRentalsDropdown = useCallback(() => (
    <>
      {markets.map(({ name, slug }) => (
        <Link href={`/collections/${slug}`} key={slug}>
          <a>{name}</a>
        </Link>
      ))}
      <Link href={`/search`}>
        <a className={styles.viewAll}>
          <p>View All on Map</p>
        </a>
      </Link>
    </>
  ), [markets]);

  const tabs = useMemo(() => {
    if (user) {
      return [
        {
          label: 'Browse Rentals',
          dropdownContent: renderBrowseRentalsDropdown(),
          withChevron: true
        },
        {
          label: 'Host with us',
          dropdownContent: renderHostDropdown(),
          withChevron: true
        },
      ];
    }
    return [
      {
        label: 'Browse Rentals',
        dropdownContent: renderBrowseRentalsDropdown(),
        withChevron: true
      },
      {
        label: 'Host with us',
        dropdownContent: renderHostDropdown(),
        withChevron: true
      },
      {
        label: (
          <Link href={'/login'}>
            <a>Login</a>
          </Link>
        ),
        onClick: onLoginClick,
      },
    ];
    // eslint-disable-next-line
  }, [user, isHostDropdownOpen, markets]);

  return (
    <>
      <HeaderMenu
        renderHostDropdown={renderHostDropdown}
        renderBrowseRentalsDropdown={renderBrowseRentalsDropdown}
      />
      <div className={clsx([styles.headerContainer, containerStyle])}>
        <div className={clsx([styles.logoContainer])}>
          <Logo />
        </div>
        {isMobile ? (
          <div
            onClick={openHeaderMenu}
            className={clsx([styles.menuIconContainer])}
          >
            <BurgerMenu />
          </div>
        ) : (
          <div className={clsx([styles.actionsContainer])}>
            <TabsNavigation tabs={tabs} containerStyle={styles.headerTabs} />
            {!user ? (
              <Link href={'/sign-up'}>
                <a>
                  <Button
                    onClick={onSignUpClick}
                    label="Sign Up"
                    containerStyle={clsx(styles.buttonContainer)}
                  />
                </a>
              </Link>
            ) : (
              <UserHeaderButton
                userFirstName={user.first_name}
                userProfilePicture={user.profile_image?.url}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
}
