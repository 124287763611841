import { SessionProvider } from '@utils/context/session';
import { ListingProvider } from '@utils/context/listing';
import { PageWithProvidersAndLayout } from '@utils/types/nextApp';
import React, { ReactElement } from "react";
import Layout from "@utils/layout";

export function withLayout<ComponentProps = any>(
  page: PageWithProvidersAndLayout<ComponentProps>, layoutProps: any = {}
) {
  page.getLayout = function (page: PageWithProvidersAndLayout<ComponentProps>) {
    return (
      <Layout {...layoutProps}>{page}</Layout>
    )
  };
  return page;
}

export function withSession<ComponentProps = any>(
  component: PageWithProvidersAndLayout<ComponentProps>,
) {
  component.sessionProvider = SessionProvider;
  return component;
}

export function withListing<ComponentProps = any>(
  component: PageWithProvidersAndLayout<ComponentProps>,
) {
  component.listingProvider = ListingProvider;
  return component;
}
