import { Loader } from '@atoms/loader';
import { BaseButtonProps } from '@utils/types/baseComponents';
import clsx from 'clsx';
import React, { useState } from 'react';
import styles from './styles.module.scss';

export type LabelComponentProps = {
  hover: boolean;
};

type Props = {
  LabelComponent: React.ComponentType<LabelComponentProps>;
  loading?: boolean;
} & BaseButtonProps;

Button.defaultProps = {
  label: '',
  LabelComponent: () => <></>,
  loading: false,
};

/**
 * Default button
 */
export function Button(props: Props) {
  const {
    disabled,
    label,
    onClick,
    LabelComponent,
    containerStyle,
    type,
    loading,
  } = props;
  const [hover, setHover] = useState(false);

  return (
    <div
      className={clsx([
        styles.container,
        disabled && styles.disabled,
        containerStyle,
      ])}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}>
      <button
        disabled={disabled}
        type={type}
        onClick={onClick}
        className={clsx([styles.button])}>
        {loading ? <Loader /> : (
          <>
            <LabelComponent hover={hover} />
            <span>{label}</span>
          </>
        )}
      </button>
    </div>
  );
}
