import { SessionTokensType } from '@utils/types/sessionTokens';
import { UserType } from '@utils/types/user';
import { apiInstance } from 'api/client';

type SignUpConfirmationResponse = {
  user: UserType;
  sessionTokens: SessionTokensType;
  redirectPage: string;
};

export async function signUpConfirmation(
  token: string,
): Promise<SignUpConfirmationResponse> {
  const response = await apiInstance.post(`/v1/signup/confirmation/${token}`);
  return {
    sessionTokens: {
      access_token: response.data.access_token,
      refresh_token: response.data.refresh_token,
    },
    user: response.data.user,
    redirectPage: response.data.metadata.page,
  };
}
