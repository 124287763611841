import { TextButton } from '@atoms/textButton';
import { ModalNames, useModals } from '@utils/context/modals';
import { useIsMobile } from '@utils/hooks/useIsMobile';
import { SegmentEventNamesEnum, SegmentEventPagesEnum, useAnalytics } from '@utils/hooks/useAnalytics';
import { Cross, ChevronDown } from 'assets/icons';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { useToggleBodyScroll } from '@utils/hooks/toggleBodyScroll';

type Props = {
  renderHostDropdown: () => JSX.Element;
  renderBrowseRentalsDropdown: () => JSX.Element;
};

export function HeaderMenu(props: Props) {
  const { renderHostDropdown, renderBrowseRentalsDropdown } = props;
  const isMobile = useIsMobile();
  const { closeModal, openModals } = useModals();
  const router = useRouter();
  const [isHostDropdownOpen, setIsHostDropdownOpen] = useState(false);
  const [isBrowseRentalsDropdownOpen, setIsBrowseRentalsDropdownOpen] = useState(false);
  const { trackEvent } = useAnalytics();
  const { disableScroll, enableScroll } = useToggleBodyScroll();

  const handleHostDropdown = () => setIsHostDropdownOpen((val) => !val);
  const handleBrowseRentalsDropdown = () => setIsBrowseRentalsDropdownOpen((val) => !val);

  useEffect(() => {
    if (openModals.includes(ModalNames.MOBILE_MENU) && isMobile) disableScroll();
    if (openModals.includes(ModalNames.MOBILE_MENU) && !isMobile) enableScroll();
  }, [openModals, isMobile]);

  const onClose = () => {
    closeModal(ModalNames.MOBILE_MENU);
    enableScroll();
  };

  const onLinkClick = (to: string) => {
    switch (to) {
      case '/search':
        trackEvent(SegmentEventNamesEnum.SEARCH_CLICK, {
          PageName: SegmentEventPagesEnum.HOME_PAGE
        });
        break;
      case '/login':
        trackEvent(SegmentEventNamesEnum.LOGIN_BUTTON_CLICK, {
          PageName: SegmentEventPagesEnum.HOME_PAGE
        });
        break;
      case '/sign-up':
        trackEvent(SegmentEventNamesEnum.SIGNUP_BUTTON_CLICK, {
          PageName: SegmentEventPagesEnum.HOME_PAGE
        });
        break;
      default:
        break;
    }
    router.push(to).then(() => onClose());
  };

  return (
    <div
      className={clsx([
        isMobile && openModals.includes(ModalNames.MOBILE_MENU)
          ? styles.open
          : styles.closed,
        styles.menu,
      ])}>
      <div className={clsx(styles.header)}>
        <Cross onClick={onClose} />
      </div>
      <div className={clsx(styles.container)}>
        <div className={clsx(styles.body)}>
          <TextButton
            containerStyle={clsx(styles.buttonText)}
            label={<span className={styles.hostButton}>Browse Rentals <ChevronDown/></span>}
            onClick={handleBrowseRentalsDropdown}
          />
          {isBrowseRentalsDropdownOpen && (
            <div className={styles.hostDropdown} onClick={onClose}>
              {renderBrowseRentalsDropdown()}
            </div>
          )}
          <TextButton
            containerStyle={clsx(styles.buttonText)}
            label={<span className={styles.hostButton}>Host with us <ChevronDown/></span>}
            onClick={handleHostDropdown}
          />
          {isHostDropdownOpen && (
            <div className={styles.hostDropdown}>
              {renderHostDropdown()}
            </div>
          )}
        </div>
        <div className={clsx(styles.footer)}>
          <TextButton
            containerStyle={clsx(styles.buttonText)}
            label='Login'
            onClick={() => onLinkClick('/login')}
          />
          <TextButton
            containerStyle={clsx(styles.buttonText)}
            label='Sign Up'
            onClick={() => onLinkClick('/sign-up')}
          />
        </div>
      </div>
    </div>
  );
}
