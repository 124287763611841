import { BaseComponentProps } from '@utils/types/baseComponents';
import { TabButton } from '@atoms/tabButton';
import clsx from 'clsx';
import React, { useState } from 'react';
import styles from './styles.module.scss';

/** Tabs prop */
export type TabsProp = {
  /** Tab name */
  label: string | JSX.Element;
  /** Tab action */
  onClick?: () => void;
  /** Dropdown content on tab hovering */
  /** Probably we will need to rework it in the future */
  /** to support TabsProp as a dropdown children */
  dropdownContent?: JSX.Element;
  withChevron?: boolean;
};

type Props = {
  /** Tabs to render */
  tabs: TabsProp[];
  /** Initial tab index */
  initialTab: number;
} & BaseComponentProps;

TabsNavigation.defaultProps = {
  initialTab: 0,
};

/**
 * Tabs navigation
 */
export function TabsNavigation(props: Props) {
  const { tabs, initialTab, containerStyle } = props;
  const [selectedTab, setSelectedTab] = useState(initialTab);

  const tabPress = (tab: TabsProp, index: number) => () => {
    const { onClick, dropdownContent } = tab;
    if (onClick) onClick();
    if (!dropdownContent) setSelectedTab(index);
  };

  return (
    <nav className={clsx([styles.container, containerStyle])}>
      {tabs.map((tab, idx) => (
        <TabButton
          key={`tab-${idx}`}
          onClick={tabPress(tab, idx)}
          selected={selectedTab === idx}
          label={tab.label}
          dropdownContent={tab.dropdownContent}
          withChevron={tab.withChevron}
        />
      ))}
    </nav>
  );
}
