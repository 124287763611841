import {
  BaseInputProps,
  InputChangeEvent,
  InputKeyboardEvent,
} from '@utils/types/baseComponents';
import clsx from 'clsx';
import React, { ForwardedRef, useState } from 'react';
import styles from './styles.module.scss';

type Props = {
  inputRef?: ForwardedRef<HTMLInputElement>;
} & BaseInputProps;

TextInput.defaultProps = {
  required: false,
  touched: false,
  error: '',
};

/** Text input */
export function TextInput(props: Omit<Props, 'value'>) {
  const {
    onPressEnter,
    onTextChange,
    label,
    placeholder,
    required,
    touched,
    containerStyle,
    error,
    type,
    name,
    inputRef,
    prefix,
  } = props;

  const [value, setValue] = useState<string>('');

  const onChange = (ev: InputChangeEvent) => {
    const { value } = ev.target;
    setValue(value);
    onTextChange && onTextChange(value);
  };

  const onKeyDown = (ev: InputKeyboardEvent) => {
    if (ev.key === 'Enter') {
      onPressEnter && onPressEnter(value);
    }
  };

  return (
    <div className={clsx([styles.container, containerStyle])}>
      <span>{label}</span>
      <input
        ref={inputRef}
        name={name}
        type={type}
        required={required}
        className={clsx([styles.input])}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onKeyDown={onKeyDown}
        prefix={prefix}
      />
      <div className={clsx(styles.error)}>
        {!!error && touched && <li>{error}</li>}
      </div>
    </div>
  );
}

// eslint-disable-next-line react/display-name
export const TextInputForwardRef = React.forwardRef<HTMLInputElement>(
  (props, ref) => <TextInput {...props} inputRef={ref} />,
);
