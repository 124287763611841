import { BaseComponentProps } from '@utils/types/baseComponents';
import { Check } from 'assets/icons';
import clsx from 'clsx';
import React, { useMemo, useState } from 'react';
import styles from './styles.module.scss';

type Props = {
  label: string;
  onChange: (checked: boolean) => void;
  value: boolean;
  variant: 'primary' | 'secondary';
} & BaseComponentProps;

Checkbox.defaultProps = {
  label: 'Label',
  value: false,
  variant: 'primary',
  onChange: (_checked: boolean) => {},
};

export function Checkbox(props: Props) {
  const { label, containerStyle, onChange, value, variant } = props;
  const [checked, setChecked] = useState(value || false);
  const isPrimary = useMemo(() => variant === 'primary', [variant]);

  const onClick = () => {
    setChecked((c) => !c);
    onChange(!checked);
  };

  return (
    <div className={clsx([styles.container, containerStyle])} onClick={onClick}>
      <div
        className={clsx([
          styles.checkBox,
          value && styles.checked,
          !isPrimary && styles.secondary,
        ])}>
        {value ? <Check /> : ''}
      </div>
      <label>{label}</label>
    </div>
  );
}
