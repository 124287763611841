import axios, { AxiosRequestConfig, AxiosError } from 'axios';
import config from '@utils/config/env';
import { getSessionTokens, setSessionTokens } from '@utils/localStorage';

// API Instance
export const apiInstance = axios.create({
  baseURL: config.backendBaseUrl,
  headers: {
    'kopa-api-key': config.backendAPIKey,
  },
});

function insertAuthToken(axiosConfig: AxiosRequestConfig) {
  if (config.isServerSide) {
    return axiosConfig;
  }
  const sessionTokens = getSessionTokens();
  if (!axiosConfig.headers) {
    axiosConfig.headers = {};
  }
  if (sessionTokens && sessionTokens.access_token) {
    axiosConfig.headers[
      'Authorization'
    ] = `Bearer ${sessionTokens.access_token}`;
  }
  return axiosConfig;
}

const setErrorInterceptor = async (error: AxiosError) => {
  const originalConfig = error.config;
  if (error.response) {
    // @ts-ignore
    if (error.response.status === 401 && typeof window !== 'undefined') {
      try {
        const tokens = getSessionTokens();
        if (tokens) {
          const response = await axios({
            baseURL: config.backendBaseUrl,
            url: `/v1/refresh_token`,
            method: 'POST',
            data: {
              refresh_token: tokens.refresh_token,
            },
            headers: {
              'kopa-api-key': config.backendAPIKey,
            },
          });
          const accessToken = response.data.access_token;
          if (accessToken) {
            setSessionTokens({
              ...tokens,
              access_token: accessToken,
            });
          }
          // @ts-ignore
          originalConfig.headers['Authorization'] = `Bearer ${accessToken}`;
        }
        return apiInstance(originalConfig);
      } catch (_error: any) {
        // Refresh token expires (?)
        if (error.response.status === 401) {
          setSessionTokens();
        }
        if (_error.response && _error.response.data) {
          return Promise.reject(_error.response.data);
        }
        return Promise.reject(_error);
      }
    }
  }
  return Promise.reject(error);
};

apiInstance.interceptors.request.use(insertAuthToken);

apiInstance.interceptors.response.use(
  (response) => response,
  (error) => setErrorInterceptor(error)
);

// Graphql CMS Instance
const cmsInstance = axios.create({
  baseURL: config.cmsGraphqlUrl,
});

export function graphqlRequest(
  /** Should match the query operation name */
  operationName: string,
  /** query enclosed in gql tag imported from `graphql-tag` */
  query: string,
  /** query variables */
  variables: any
) {
  const graphqlQuery = {
    operationName,
    query,
    variables,
  };

  return cmsInstance.post('', graphqlQuery, {});
}

// Guesty Api instance
const guestyAuth = `${config.guestyApiUsername}:${config.guestyApiPassword}`;
export const guestyInstance = axios.create({
  baseURL: config.guestyApiBaseUrl,
  headers: {
    Authorization: `Basic ${Buffer.from(guestyAuth).toString('base64')}`,
  },
});
