import { Button } from '@atoms/button';
import { Logo } from '@atoms/logo';
import { TextInput } from '@atoms/textInput';
import { Checkbox } from '@atoms/checkbox';
import { BaseComponentProps } from '@utils/types/baseComponents';
import { useGetMarkets } from '@utils/hooks/api/useGetMarkets';
import { isEmail } from '@utils/helpers';
import { MarketType } from '@utils/types/city';
import { subscribe, SubscribeTags } from '@api/homepage/subscribe';
// import { Airbnb, Facebook, Instagram } from 'assets/icons';
import clsx from 'clsx';
import Link from 'next/link';
import React, { useEffect, useMemo, useState } from 'react';
import styles from './styles.module.scss';

const defaultCheckboxes = {
  isRenter: false,
  isOwner: false,
  representCompany: false,
};

type Props = {} & BaseComponentProps;

export function Footer(props: Props) {
  const { containerStyle } = props;
  const [checkboxes, setCheckboxes] = useState(defaultCheckboxes);
  const [email, setEmail] = useState('');
  const [subscribeSuccess, setSubscribeSuccess] = useState(false);
  const [markets, setMarkets] = useState<MarketType[]>();

  const { data } = useGetMarkets(!markets);

  useEffect(() => {
    if (data) setMarkets(data.map(({ name, slug }) => ({ name, slug: slug.current })));
  }, [data]);

  const renderCheckboxes = useMemo(
    () => (
      <div className={styles.checkboxes}>
        {[
          { label: 'I’m a renter', key: 'isRenter' },
          { label: 'I’m an owner', key: 'isOwner' },
          { label: 'I represent a company', key: 'representCompany' },
        ].map(({ label, key }) => (
          <Checkbox
            variant="secondary"
            label={label}
            containerStyle={styles.inputContainer}
            value={checkboxes[key as keyof typeof defaultCheckboxes]}
            onChange={() =>
              setCheckboxes((val) => ({
                ...val,
                [key]: !val[key as keyof typeof defaultCheckboxes],
              }))
            }
            key={key}
          />
        ))}
      </div>
    ),
    [checkboxes]
  );

  const onSubscribeClick = async () => {
    const tags: SubscribeTags[] = [];
    if (checkboxes.isOwner) {
      tags.push('owner');
    }
    if (checkboxes.isRenter) {
      tags.push('individual');
    }
    if (checkboxes.representCompany) {
      tags.push('company');
    }
    await subscribe({ email, tags });
    setSubscribeSuccess(true);
  };

  return (
    <div className={clsx([styles.container, containerStyle])}>
      <div className={styles.body}>
        <div className={styles.icons}>
          <div className={styles.logo}>
            <Logo />
          </div>
          {/*<div className={styles.socialIcons}>*/}
          {/*  <Facebook className={styles.icon} />*/}
          {/*  <Instagram className={styles.icon} />*/}
          {/*  <Airbnb className={styles.icon} />*/}
          {/*</div>*/}
        </div>
        <div className={styles.section}>
          <div className={styles.title}>CONNECT</div>
          <a href="mailto:support@rovetravel.com">Contact us</a>
        </div>
        <div className={styles.section}>
          <div className={styles.title}>COMPANY</div>
          <Link href={'/search'}>
            <a>Browse all listings</a>
          </Link>
          <a
            target="_blank"
            href="https://owners.rovetravel.com"
            rel="noopener noreferrer"
          >
            Become an owner
          </a>
          <span>Careers</span>
        </div>
        <div className={styles.section}>
          <div className={styles.title}>MARKETS</div>
          {data &&
            data.map(({ name, slug }) => (
              <Link href={`/collections/${slug.current}`} key={slug.current}>
                <a>{name}</a>
              </Link>
            ))}
        </div>
        <div className={clsx([styles.community])}>
          <div className={styles.title}>JOIN THE COMMUNITY</div>
          <TextInput
            placeholder="Your Email"
            containerStyle={styles.input}
            onTextChange={setEmail}
          />
          {renderCheckboxes}
          <Button
            label="Subscribe"
            containerStyle={styles.button}
            disabled={!isEmail(email) || subscribeSuccess}
            onClick={onSubscribeClick}
          />
          {subscribeSuccess && <p className={styles.successText}>Success!</p>}
        </div>
      </div>
      <div className={styles.bottom}>
        <span>&copy; 2022 Rove travel, Inc. All rights reserved.</span>
        <div className={styles.links}>
          <a
            target="_blank"
            href="https://assets.website-files.com/60a2ac87e0551a10d703b77d/60c7e0715b90bc47889066e8_Rove%20Ventures%20Inc.-Terms%20of%20Service-03_01_2021.pdf"
            rel="noopener noreferrer"
          >
            Terms of Service and Privacy Policy
          </a>
          {/*<span>Privacy Policy</span>*/}
        </div>
      </div>
    </div>
  );
}
