import { BaseButtonProps } from '@utils/types/baseComponents';
import { ChevronDown } from 'assets/icons';
import clsx from 'clsx';
import React, { useState } from 'react';
import styles from './styles.module.scss';

type Props = {
  label?: string | JSX.Element;
  selected: boolean;
  dropdownContent?: JSX.Element;
  withChevron?: boolean;
} & BaseButtonProps;

TabButton.defaultProps = {
  label: 'Tab Button',
};

/**
 * Default tab button
 */
export function TabButton(props: Props) {
  const { label, selected, onClick, dropdownContent, withChevron } = props;
  const [isDropdownShown, setIsDropdownShown] = useState(false);

  const onMouseOver = () => {
    if (dropdownContent) setIsDropdownShown(true);
  };
  const onMouseLeave = () => {
    if (dropdownContent) setIsDropdownShown(false);
  };

  return (
    <div
      className={clsx([styles.tabButton, selected && styles.selected])}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      onClick={onClick}>
      <div className={clsx(styles.text, selected && styles.selected)}>
        <div className={styles.label}>{label}</div>
        {!!withChevron && <ChevronDown style={{ marginLeft: '8px' }} />}
      </div>
      {dropdownContent && (
        <div className={clsx([styles.tabDropdown, isDropdownShown && styles.open])}>
          {dropdownContent}
        </div>
      )}
    </div>
  );
}
