import { useSession } from '@utils/context/session';
import { BaseComponentProps } from '@utils/types/baseComponents';
import clsx from 'clsx';
import React from 'react';
import Link from 'next/link';
import styles from './styles.module.scss';
import { useRouter } from 'next/router';

type Props = {
  isVisible: boolean;
} & BaseComponentProps;

ProfileHeaderMenu.defaultProps = {};

export function ProfileHeaderMenu(props: Props) {
  const { containerStyle, isVisible } = props;
  const router = useRouter();
  const { logOut } = useSession();

  const goToAccountSettings = () => router.push('/account-settings');

  return (
    <div
      className={clsx([
        styles.profileHeaderMenuContainer,
        isVisible && styles.open,
        containerStyle,
      ])}>
      <Link href="reservations-and-stays">
        <a className={styles.buttonContainer}>
          Reservations & Stays
        </a>
      </Link>
      <Link href="reviews">
        <a className={styles.buttonContainer}>
          Reviews
        </a>
      </Link>
      <div className={styles.divider} />
      {/*<span className={styles.buttonContainer}>Favorites</span>*/}
      <span className={styles.buttonContainer} onClick={goToAccountSettings}>Account Settings</span>
      {/*<span className={styles.buttonContainer}>Account Referrals</span>*/}
      <div className={styles.divider} />
      {/*<span className={styles.buttonContainer}>Account Help</span>*/}
      <span className={styles.buttonContainer} onClick={logOut}>
        Sign Out
      </span>
    </div>
  );
}
