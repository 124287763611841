import { colors } from '@utils/constants/colors';

type ColorsType = keyof typeof colors;

export function setBackgroundColor(color: ColorsType = 'roveOffWhite') {
  document.body.style.backgroundColor = colors[color];
}

export function listingTitleWithoutByRove(title: string) {
  return title.replace(' by Rove', '');
}

export function isEmail(email: string) {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(email);
}

export function splitByCommas(val: string) {
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function getYoutubeThumbnail(youtubeURL?: string) {
  if (!youtubeURL) return undefined;
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = youtubeURL.match(regExp);
  if (match && match[7].length === 11) {
    return `https://img.youtube.com/vi/${match[7]}/0.jpg`
  } else {
    return undefined;
  }
}

export const stringifyArrayForGQL = (data: string[]) =>
  JSON.stringify(data).replace(/"([^(")"]+)":/g, '$1:');

