import { BaseComponentProps } from '@utils/types/baseComponents';
import clsx from 'clsx';
import React from 'react';
import styles from './styles.module.scss';

type Props = {
  type?: 'button' | 'content';
} & BaseComponentProps;

Loader.defaultProps = {
  type: 'button',
};

/**
 * Loader that fits button by default
 */
export function Loader(props: Props) {
  const { type, containerStyle } = props;

  return (
    <div className={clsx([
      styles.buttonLoader,
      containerStyle,
      type === 'content' && styles.forContent,
    ])}>
      <div />
      <div />
      <div />
      <div />
    </div>
  );
}
