import {
  setSessionTokens as setSessionTokensInLocalStorage,
  getSessionTokens,
} from '@utils/localStorage';
import { toast } from 'react-hot-toast';
import { SessionTokensType } from '@utils/types/sessionTokens';
import { UserType } from '@utils/types/user';
import { useAnalytics } from '@utils/hooks/useAnalytics';
import { PaymentMethod } from '@utils/types/paymentMethods';
import { getUserDetails } from 'api/auth/getUserDetails';
import { login as apiLogin } from 'api/auth/login';
import { signUpConfirmation } from 'api/auth/signupConfirmation';
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';

type ContextValues = {
  logOut: () => void;
  login: (username: string, password: string) => Promise<void>;
  confirmSignUp: (token: string) => Promise<string>;
  /**
   * This should only be used for the social signin/singup which happens in the
   * social-login/callback pag. Please avoid using it at all.
   */
  setSessionTokens: (accessToken: string, refreshToken: string) => void;
  user?: UserType;
  sessionTokens?: SessionTokensType;
  addPaymentMethod: (paymentMethod: PaymentMethod) => void;
};

const SessionContext = createContext<ContextValues>({
  login: async (_username: string, _password: string) => {},
  confirmSignUp: async (_token: string) => '',
  logOut: () => {},
  setSessionTokens: () => {},
  addPaymentMethod: () => {},
});
SessionContext.displayName = 'SessionContext';

export function SessionProvider(props: PropsWithChildren<{}>) {
  const { children } = props;
  const [user, setUser] = useState<UserType>();
  const [sessionTokens, setSessionTokens] = useState<SessionTokensType>();
  const { identifyUser } = useAnalytics();

  const login = async (username: string, password: string) => {
    const { sessionTokens: tokens, user } = await apiLogin(username, password);
    setSessionTokens(tokens);
    setUser(user);
  };

  const logOut = () => {
    setSessionTokens(undefined);
    setUser(undefined);
    setSessionTokensInLocalStorage();
  };

  const confirmSignUp = async (token: string) => {
    const { sessionTokens: tokens, user, redirectPage } = await signUpConfirmation(token);
    setSessionTokens(tokens);
    setUser(user);
    return redirectPage;
  };

  const getUser = async () => {
    try {
      const { user } = await getUserDetails();
      setUser(user);
    } catch (error: any) {
      if (error.isAxiosError) {
        if (error.response.status !== 401) {
          toast('Something went wrong, please try again.');
          console.error(error);
        }
      } else {
        toast('Something went wrong, please try again.');
        console.error(error);
      }
    }
  };

  const manuallySetSessionTokens = (
    accessToken: string,
    refreshToken: string,
  ) => {
    setSessionTokens({
      access_token: accessToken,
      refresh_token: refreshToken,
    });
  };

  const addPaymentMethod = async (payment_method: PaymentMethod) => {
    if (user) setUser({ ...user, payment_methods: [...user.payment_methods, payment_method] });
  };

  useEffect(() => {
    if (sessionTokens) {
      setSessionTokensInLocalStorage(sessionTokens);
      getUser();
    }
  }, [sessionTokens]);

  useEffect(() => {
    const tokens = getSessionTokens();
    if (tokens) {
      setSessionTokens(tokens);
    }
  }, []);

  useEffect(() => {
    if (user) {
      identifyUser(user.id, {
        name: user.name,
        email: user.email,
      })
    }
    // eslint-disable-next-line
  }, [user]);

  return (
    <SessionContext.Provider
      value={{
        login,
        confirmSignUp,
        sessionTokens,
        user,
        logOut,
        setSessionTokens: manuallySetSessionTokens,
        addPaymentMethod,
      }}>
      {children}
    </SessionContext.Provider>
  );
}

export function useSession() {
  const context = useContext(SessionContext);
  if (context === undefined) {
    throw new Error('useSession must be used within an SessionProvider');
  }
  return context;
}
