import { BaseButtonProps } from '@utils/types/baseComponents';
import clsx from 'clsx';
import React from 'react';
import styles from './styles.module.scss';

type Props = {} & BaseButtonProps;

export function TextButton(props: Props) {
  const { label, onClick, containerStyle } = props;

  return (
    <div className={clsx([styles.container, containerStyle])} onClick={onClick}>
      <span>{label}</span>
    </div>
  );
}
