// import { Image } from '@atoms/image';
import { ProfileHeaderMenu } from '@molecules/ProfileHeaderMenu';
import { BaseComponentProps } from '@utils/types/baseComponents';
import { useClickOutside } from '@utils/hooks/useClickOutside';
import clsx from 'clsx';
import React, { useRef, useState } from 'react';
import styles from './styles.module.scss';

type Props = {
  userProfilePicture: string;
  userFirstName: string;
} & BaseComponentProps;

UserHeaderButton.defaultProps = {};

export function UserHeaderButton(props: Props) {
  const { containerStyle, userFirstName, userProfilePicture } = props;
  const [isVisible, setIsVisible] = useState(false);
  const profileMenuRef = useRef<HTMLDivElement>(null);

  const toggleProfileMenu = () => {
    setIsVisible((v) => !v);
  };

  useClickOutside(profileMenuRef, () => setIsVisible(false));

  return (
    <div
      ref={profileMenuRef}
      className={clsx([styles.userHeaderButton, containerStyle])}
    >
      <div
        onClick={toggleProfileMenu}
        className={styles.userHeaderButtonContainer}>
        {/*{!!userProfilePicture ? (*/}
        {/*  <Image*/}
        {/*    src={userProfilePicture}*/}
        {/*    alt={`${userFirstName} profile picture`}*/}
        {/*    containerStyle={styles.profilePicture}*/}
        {/*  />*/}
        {/*) : (*/}
        {/*  <div className={clsx([styles.profilePicture, styles.blankImage])} />*/}
        {/*)}*/}
        <span className={styles.label}>Hi, {userFirstName}</span>
      </div>
      <ProfileHeaderMenu isVisible={isVisible} />
    </div>
  );
}
