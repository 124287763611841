import { BaseComponentProps } from "@utils/types/baseComponents";
import { Footer } from "@organisms/footer";
import { Header, HeaderProps } from "@organisms/header";
import { PropsWithChildren } from "react";

type Props = {
  footerProps: BaseComponentProps;
  headerProps: HeaderProps & BaseComponentProps;
}
export default function Layout(props: PropsWithChildren<Props>) {
  const { headerProps, footerProps, children } = props;
  return (
    <>
      <Header {...headerProps} />
      <main>{children}</main>
      <Footer {...footerProps} />
    </>
  )
}