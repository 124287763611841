import { ListingPriceEstimateType } from '@utils/types/listingPriceEstimate';
import { DatesRange } from '@utils/types/calendar';
import { ListingType } from '@utils/types/listing';
import { getListingInfo, setListingInfo } from '@utils/localStorage';
import { useRouter } from 'next/router';
import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect, useMemo,
  useState,
} from 'react';

type ContextValues = {
  listing?: ListingType;
  listingPriceEstimate?: ListingPriceEstimateType;
  dateRange: DatesRange;
  changeListing: (data: ListingType) => void;
  changeListingPriceEstimate: (data: ListingPriceEstimateType) => void;
  changeDateRange: (data: DatesRange) => void;
  setListingFieldsForBooking: (
    item: ListingType,
    estimate: ListingPriceEstimateType,
    dates: DatesRange,
    bookingID: string | undefined,
  ) => void;
  bookingID?: string;
  changeBookingID: (id: string) => void;
  isInstantBooking: boolean
};

const ListingContext = createContext<ContextValues>({
  dateRange: { from: null, to: null },
  changeListing: () => {},
  changeListingPriceEstimate: () => {},
  changeDateRange: () => {},
  setListingFieldsForBooking: () => {},
  changeBookingID: () => {},
  isInstantBooking: false,
});
ListingContext.displayName = 'ListingContext';

export function ListingProvider(props: PropsWithChildren<{}>) {
  const { children } = props;
  const router = useRouter();
  const [listing, setListing] = useState<ListingType>();
  const [listingPriceEstimate, setListingPriceEstimate] = useState<ListingPriceEstimateType>();
  const [dateRange, setDateRange] = useState<DatesRange>({ from: null, to: null });
  const [bookingID, setBookingID] = useState<string>();

  // If less than 30 days min stay then the booking is instant
  const isInstantBooking = useMemo(() => listing?.min_stay! < 30, [listing]);

  useEffect(() => {
    if (listing && listingPriceEstimate) {
      setListingInfo({ listing, listingPriceEstimate, dateRange, bookingID });
    }
  }, [listing, listingPriceEstimate, dateRange, bookingID]);

  const changeListing = useCallback((fields: ListingType) => {
    setListing({ ...listing, ...fields });
  }, [listing]);

  const changeListingPriceEstimate = useCallback((fields: ListingPriceEstimateType) => {
    setListingPriceEstimate({ ...listingPriceEstimate, ...fields });
  }, [listingPriceEstimate]);

  const changeDateRange = (fields: DatesRange) => {
    setDateRange(fields);
  };

  const changeBookingID = (id: string) => {
    setBookingID(id);
  };

  const setListingFieldsForBooking = useCallback((
    item: ListingType,
    estimate: ListingPriceEstimateType,
    dates: DatesRange,
    id: string | undefined,
  ) => {
    changeListing(item);
    changeListingPriceEstimate(estimate);
    setDateRange(dates);
    if (id) setBookingID(id);
  }, [changeListing, changeListingPriceEstimate]);

  useEffect(() => {
    const listingInfoInSessionStorage = getListingInfo();
    if (listingInfoInSessionStorage) {
      setListingFieldsForBooking(
        listingInfoInSessionStorage.listing,
        listingInfoInSessionStorage.listingPriceEstimate,
        listingInfoInSessionStorage.dateRange,
        listingInfoInSessionStorage.bookingID,
      )
    } else if (!listing && window.location.pathname.includes('checkout')) {
      /*
         redirect from Checkout page to Home page
         if there is no info about Listing in Session Storage
      */
      router.push('/home');
    }
    // eslint-disable-next-line
  }, []);

  return (
    <ListingContext.Provider
      value={{
        listing,
        listingPriceEstimate,
        dateRange,
        changeListing,
        changeListingPriceEstimate,
        changeDateRange,
        setListingFieldsForBooking,
        bookingID,
        changeBookingID,
        isInstantBooking,
      }}
    >
      {children}
    </ListingContext.Provider>
  );
}

export function useListing() {
  const context = useContext(ListingContext);
  if (context === undefined) {
    throw new Error('useListing must be used within an ListingProvider');
  }
  return context;
}
